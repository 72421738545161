import { createTheme } from '@mui/material/styles';

export const customTheme = createTheme({
   palette: {
      primary: {
         main: '#00AEEF',
         dark: '#21bbf3',
         light: '#fff',
         contrastText: '#fff',
      },
      secondary: {
         main: '#141414',
      },
      error: {
         main: '#ff5449', // Red
         light: '#ec6666', // Light Red
      },
      warning: {
         main: '#e6ff31', // Yellow
      },
      info: {
         main: '#147ad6', // Blue
         light: '#79d2de', // Light Blue
      },
      success: {
         main: '#00AEEF', // Dark Green
      },
   },
});
