import { Button, TextField } from '@mui/material';
import { useContext, useState } from 'react';
import { SignUpModel } from '../models/signup.model';
import { WarningMessage } from '../models/warning-message.model';
import { Link, useNavigate } from 'react-router-dom';
import { createUser } from '../services/user.service';
import { isEmail } from '../utils/helper';
import { Alert } from '@mui/material';
import { SimpleModal } from '../components/shared/modals/SimpleModal';

export function SignUp() {
   const [form, setForm] = useState({
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      repeatPassword: '',
   } as SignUpModel);
   const [topMessage, setTopMessage] = useState({
      error: false,
      message: null,
   } as WarningMessage);

   const navigate = useNavigate();

   const submit = async (e: any) => {
      e.preventDefault();
      try {
         const response = await createUser(form);
         if (!response.error) {
            setForm({
               firstname: '',
               lastname: '',
               email: '',
               password: '',
               repeatPassword: '',
            } as SignUpModel);
            setTopMessage({
               error: false,
               message: 'Account successfully created',
            });
            navigate('/login');
         } else {
            setTopMessage({ error: true, message: response.error });
         }
      } catch (e: any) {
         setTopMessage({ error: true, message: e.toString() });
      }
   };

   return (
      <SimpleModal open={true}>
         {topMessage.message !== null && (
            <Alert className="mb-2" variant="outlined" severity={topMessage.error ? 'error' : 'success'}>
               {topMessage.message}
            </Alert>
         )}
         <form onSubmit={submit}>
            <h2>Create your account</h2>
            <div className="flex column mt-4">
               <div className="flex between mb-3">
                  <TextField className="mr-1 w-100" label="First Name" variant="outlined" value={form.firstname} onChange={(event: any) => setForm({ ...form, firstname: event.target.value })} />
                  <TextField className="ml-1 w-100" label="Last Name" variant="outlined" value={form.lastname} onChange={(event: any) => setForm({ ...form, lastname: event.target.value })} />
               </div>
               <TextField
                  label="Email"
                  type={'email'}
                  variant="outlined"
                  value={form.email}
                  onChange={(event: any) => setForm({ ...form, email: event.target.value })}
                  error={!isEmail(form.email) && form.email?.length > 0}
                  helperText={!isEmail(form.email) && form.email?.length > 0 ? 'Please provide a valid email address.' : ''}
                  required={true}
               />
               <div className="flex column mt-5">
                  <TextField
                     className="mb-3"
                     label="Password"
                     type={'password'}
                     variant="outlined"
                     value={form.password}
                     onChange={(event: any) => setForm({ ...form, password: event.target.value })}
                     helperText={form.password?.length < 8 && form.password?.length > 0 ? 'Password needs to be at least 8 characters long.' : ''}
                     required={true}
                  />
                  <TextField
                     label="Repeat Password"
                     type={'password'}
                     variant="outlined"
                     value={form.repeatPassword}
                     onChange={(event: any) => setForm({ ...form, repeatPassword: event.target.value })}
                     error={form.password !== form.repeatPassword && form.repeatPassword?.length > 0}
                     helperText={form.password !== form.repeatPassword && form.repeatPassword?.length > 0 ? 'Passwords need to match.' : ''}
                     required={true}
                  />
               </div>
               <Button className="mt-4" variant="contained" type="submit">
                  SIGN UP
               </Button>
               <Link className="mt-4 small-link" to="/login">
                  You already have an account? Sign In
               </Link>
            </div>
         </form>
      </SimpleModal>
   );
}
