import { MessageSearchModel } from "../models/search.model";

const API_URL = process.env.REACT_APP_API as String;
const apiEndpoint = 'messages';

export const getMessages = async (searchModel: MessageSearchModel): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetch(
      `${API_URL}${apiEndpoint}?search=${searchModel?.search}&offset=${(searchModel?.page ?? 0) * (searchModel?.rowsPerPage ?? 100)}&limit=${searchModel?.rowsPerPage ?? 100}&orderBy=${
         searchModel?.orderBy
      }&asc=${searchModel?.asc}&batchId=${searchModel?.batchId}`,
      requestOptions
   ).then(async (response) => {
      return { status: response.status, data: await response.json() };
   });
};

export const getMessage = async (id: string): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetch(`${API_URL}${apiEndpoint}/${id}`, requestOptions).then(async (response) => {
      return { status: response.status, data: await response.json() };
   });
};

export const getReport = async (start: Date, end: Date): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetch(`${API_URL}${apiEndpoint}/report?start=${start}&end=${end}`, requestOptions).then(async (response) => {
      return { status: response.status, data: await response.json() };
   });
};

// export const createBatch = async (model: any): Promise<any> => {
//    const requestOptions = {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify(model),
//    };

//    return fetch(`${API_URL}${apiEndpoint}`, requestOptions).then(async (response) => {
//       return { status: response.status, data: await response.json() };
//    });
// };

// export const patchBatch = async (patch: PatchModel): Promise<any> => {
//    const requestOptions = {
//       method: 'PATCH',
//       headers: { 'Content-Type': 'application/json' },
//       credentials: 'include' as any,
//       body: JSON.stringify(patch),
//    };
//    return fetch(`${API_URL}${apiEndpoint}`, requestOptions).then(async (response) => {
//       return { status: response.status, data: await response.json() };
//    });
// };
