import { PatchModel } from "../models/path.model";
import { BatchSearchModel } from "../models/search.model";

const API_URL = process.env.REACT_APP_API as String;
const apiEndpoint = 'batches';

export const getBatches = async (searchModel: BatchSearchModel): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetch(
      `${API_URL}${apiEndpoint}?search=${searchModel?.search}&offset=${(searchModel?.page ?? 0) * (searchModel?.rowsPerPage ?? 100)}&limit=${searchModel?.rowsPerPage ?? 100}&orderBy=${
         searchModel?.orderBy
      }&asc=${searchModel?.asc}&type=${searchModel?.type}`,
      requestOptions
   ).then(async (response) => {
      return { status: response.status, data: await response.json() };
   });
};

export const getBatch = async (id: string): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetch(`${API_URL}${apiEndpoint}/${id}`, requestOptions).then(async (response) => {
      return { status: response.status, data: await response.json() };
   });
};

export const createBatch = async (model: any): Promise<any> => {
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(model),
      credentials: 'include' as any,
   };

   return fetch(`${API_URL}${apiEndpoint}`, requestOptions).then(async (response) => {
      return { status: response.status, data: await response.json() };
   });
};

export const patchBatch = async (patch: PatchModel): Promise<any> => {
   const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
      body: JSON.stringify(patch),
   };
   return fetch(`${API_URL}${apiEndpoint}`, requestOptions).then(async (response) => {
      return { status: response.status, data: await response.json() };
   });
};

export const refreshStatus = async (id: string): Promise<any> => {
   const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetch(`${API_URL}${apiEndpoint}/refresh/${id}`, requestOptions).then(async (response) => {
      return { status: response.status, data: await response.json() };
   });
}
