import { Dashboard } from '../components/Dashboard';
import { Nav } from '../components/shared/Nav';

export function Home(props: { children?: React.ReactNode }) {
   return (
      <div className="home flex ">
         <Nav />
         <div className="home-content">{props.children}</div>
      </div>
   );
}
