import './App.css';
import { ThemeProvider } from '@mui/material';
import { authStatus } from './services/user.service';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';
import { LicenseInfo } from '@mui/x-license-pro';
import { SnackBarProvider } from './contexts/SnackbarContext';
import { customTheme } from './static/themes/theme';
import { useState } from 'react';
import { AuthProvider } from './providers/AuthProvider';
import { Header } from './components/shared/Header';
import { Footer } from './components/shared/Footer';
import { SignIn } from './pages/SignIn';
import { SignUp } from './pages/SignUp';
import { Home } from './pages/Home';
import { Users } from './components/Users';
import { Dashboard } from './components/Dashboard';
import { Messages } from './components/Messages';
import { Emails } from './components/Emails';
import { MessageBatch } from './components/MessageBatch';

LicenseInfo.setLicenseKey('a67bcd5b462e87e921c4d9c50de54c2bTz03ODcyNixFPTE3MzE2MDcyMzMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function App() {
   const [isAuthenticated, setAuthenticated] = useState(authStatus().authenticated);

   return (
      <ThemeProvider theme={customTheme}>
         <BrowserRouter>
            <AuthContext.Provider value={{ isAuthenticated, setAuthenticated: setAuthenticated }}>
               <AuthProvider />
               <SnackBarProvider>
                  <div className="App">
                     <Header />
                     <main>
                        <Routes>
                           {/* <Route path="/" element={<Dashboard accountId={selectedAccountId} setAccountId={setSelectedAccountId} setSelectedAccountName={setSelectedAccountName} />} /> */}
                           <Route path="/login" element={<SignIn />} />
                           <Route path="/signup" element={<SignUp />} />

                           <Route path="/" element={<Home children={<Dashboard />} />} />
                           <Route path="/users" element={<Home children={<Users />} />} />
                           <Route path="/messages" element={<Home children={<Messages />} />} />
                           <Route path="/messages/:batchId" element={<Home children={<MessageBatch />} />} />
                           <Route path="/emails" element={<Home children={<Emails />} />} />

                           <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                     </main>
                     <Footer />
                  </div>
               </SnackBarProvider>
            </AuthContext.Provider>
         </BrowserRouter>
      </ThemeProvider>
   );
}

export default App;
