import React, { useEffect } from 'react';
import { authStatus } from '../services/user.service';

interface IAuthContext {
   isAuthenticated: Boolean;
   setAuthenticated: (state: boolean) => void;
}

const AuthContextDefault = {
   isAuthenticated: authStatus().authenticated,
   setAuthenticated: () => {},
};

export const AuthContext = React.createContext<IAuthContext>(AuthContextDefault);
