import { Box, FormControl, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid';
import { Role, UserRole } from '../../../models/user.model';

export function RolesEdit(props: { params: GridRenderCellParams<UserRole>; roles: Role[] }) {
   const { id, field, value } = props.params;
   const currentRoles = (value ?? []) as UserRole[];
   const apiRef = useGridApiContext();

   return (
      <Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
         <FormControl fullWidth className="no-round">
            <Select
               defaultOpen={true}
               value={currentRoles}
               label="Status"
               multiple
               renderValue={(selected: UserRole[]) => selected.map((userRole: UserRole) => userRole.role.role).join(', ')}
               onChange={(_event: any, val: any) => {
                  var newRoles: UserRole[] = [];
                  const changedRoleId = val.props.value;
                  const exists = currentRoles.map((userRole: UserRole) => userRole.roleId).includes(changedRoleId);
                  if (!exists) {
                     const roleToAdd = props.roles.filter((role: Role) => role.id === changedRoleId)[0];
                     newRoles = [...currentRoles, { roleId: roleToAdd.id, role: roleToAdd }];
                  } else {
                     newRoles = currentRoles.filter((userRole: UserRole) => userRole.roleId !== changedRoleId);
                  }
                  apiRef.current.setEditCellValue({
                     id,
                     field,
                     value: newRoles,
                  });
               }}>
               {props.roles.map((role: Role, index: number) => (
                  <MenuItem key={index} value={role.id}>
                     <Checkbox checked={currentRoles.map((userRole: UserRole) => userRole.roleId).indexOf(role.id) > -1} />
                     <ListItemText primary={role.role} />
                  </MenuItem>
               ))}
            </Select>
         </FormControl>
      </Box>
   );
}
