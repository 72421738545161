import { Button, TextField } from '@mui/material';
import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import { WarningMessage } from '../models/warning-message.model';
import { AuthContext } from '../contexts/AuthContext';
import { SignInModel } from '../models/signin.model';
import { SimpleModal } from '../components/shared/modals/SimpleModal';
import { isEmail } from '../utils/helper';
import { signin } from '../services/user.service';

declare global {
   interface Window {
      onSignIn: CallableFunction;
   }
}

export function SignIn() {
   const { setAuthenticated } = useContext(AuthContext);
   const [form, setForm] = useState({
      email: '',
      password: '',
      staySigned: false,
   } as SignInModel);
   const [topMessage, setTopMessage] = useState({
      error: false,
      message: null,
   } as WarningMessage);

   const navigate = useNavigate();

   const submit = async (e: any) => {
      e.preventDefault();
      try {
         const response = await signin(form);
         if (response.status === 200 && response?.data?.data) {
            setForm({
               email: '',
               password: '',
            } as SignInModel);
            localStorage.setItem('token', response?.data?.data);
            setAuthenticated(true);
            navigate('/');
         } else {
            setTopMessage({
               error: true,
               message: response?.data?.message || 'Something went wrong. Please try again.',
            });
         }
      } catch (e: any) {
         setTopMessage({ error: true, message: e.toString() });
      }
   };

   return (
      <SimpleModal open={true}>
         {topMessage.message !== null && (
            <Alert className="mb-2" variant="outlined" severity={topMessage.error ? 'error' : 'success'}>
               {topMessage.message}
            </Alert>
         )}
         <div>
            <form onSubmit={submit}>
               <h2>Connect with your account</h2>
               <div className="flex column mt-4">
                  <TextField
                     label="Email"
                     type={'email'}
                     variant="outlined"
                     value={form.email}
                     onChange={(event: any) => setForm({ ...form, email: event.target.value })}
                     error={!isEmail(form.email) && form.email?.length > 0}
                     helperText={!isEmail(form.email) && form.email?.length > 0 ? 'Please provide a valid email address.' : ''}
                     required={true}
                  />
                  <TextField
                     className="mt-3 mb-4"
                     label="Password"
                     type={'password'}
                     variant="outlined"
                     value={form.password}
                     onChange={(event: any) => setForm({ ...form, password: event.target.value })}
                     required={true}
                  />
                  <Button className="mt-4" variant="contained" type="submit">
                     SIGN IN
                  </Button>
               </div>
            </form>
            <div className="flex between items-center">
               {/* <Link className="mt-4 small-link" to="/signup">
                  Create an account
               </Link> */}
               {/* <Link className="mt-4 small-link" to="/reset-password">
                  Forgot password?
               </Link> */}
            </div>
         </div>{' '}
      </SimpleModal>
   );
}
