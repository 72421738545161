import { Link, useLocation, useNavigate } from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import MessageIcon from '@mui/icons-material/Message';
import EmailIcon from '@mui/icons-material/Email';
import logo from '../../static/assets/logo.png';
import LogoutIcon from '@mui/icons-material/Logout';
import { AuthContext } from '../../contexts/AuthContext';
import { useContext } from 'react';
import { Button } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';

export function Nav() {
   const navigate = useNavigate();
   const { setAuthenticated } = useContext(AuthContext);
   const location = useLocation();

   const currentPath = location.pathname;
   const categoryPath = currentPath.split('/')[1] || '';

   return (
      <nav className="flex column between items-center">
         <div className="flex column">
            <Link to={'/'}>
               <img className="logo" src={logo} alt="" />
            </Link>
            <div className="links flex column w-100">
               <div className={`menu-link ${!categoryPath ? ' selected' : ''} `} onClick={() => navigate('/')}>
                  <div>
                     <DashboardIcon />
                     <p>Dashboard</p>
                  </div>
               </div>
               <div className={`menu-link ${categoryPath === 'users' ? ' selected' : ''}`} onClick={() => navigate('/users')}>
                  <div>
                     <GroupIcon />
                     <p>Users</p>
                  </div>
               </div>
               <div className={`menu-link ${categoryPath === 'messages' ? ' selected' : ''}`} onClick={() => navigate('/messages')}>
                  <div>
                     <MessageIcon />
                     <p>Messages</p>
                  </div>
               </div>
               <div className={`menu-link ${categoryPath === 'emails' ? ' selected' : ''}`} onClick={() => navigate('/emails')}>
                  <div>
                     <EmailIcon />
                     <p>Emails</p>
                  </div>
               </div>
            </div>
         </div>
         <Button variant="text" color="secondary" startIcon={<LogoutIcon />} onClick={() => setAuthenticated(false)}>
            Log out
         </Button>
      </nav>
   );
}
