import { Role, User } from '../models/user.model';
import { useState, useEffect } from 'react';
import { getUser, getUsers, patchUser } from '../services/user.service';
import { SearchModel } from '../models/search.model';
import { useSnackBar } from '../contexts/SnackbarContext';
import { DataGridPro, GridColDef, GridRenderCellParams, GridSortModel, GridCellEditCommitParams, GridCellParams } from '@mui/x-data-grid-pro';
import { StatusEdit } from './shared/datagrid/StatusEdit';
import { RenderStatus } from './shared/datagrid/RenderStatus';
import { RolesEdit } from './shared/datagrid/EditRoles';
import { RenderRoles } from './shared/datagrid/RenderRoles';
import TimeAgo from 'timeago-react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { ConfirmModal } from './shared/modals/ConfirmModal';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { PatchModel } from '../models/path.model';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export function Users() {
   const [loading, setLoading] = useState<boolean>(false);
   const [users, setUsers] = useState<User[]>([]);
   const [total, setTotal] = useState(0);
   const [searchObj, setSearchObj] = useState<SearchModel>({
      page: 0,
      rowsPerPage: 100,
      search: '',
      orderBy: 'firstname',
      asc: true,
   });
   const [editCellObj, setEditCellObj] = useState<string>();
   const [selectedObj, setSelectedObj] = useState<any>(null);
   const [roles, setRoles] = useState<Role[]>([]);
   const [gridHeight, setGridHeight] = useState<number>(200);

   const snackbar = useSnackBar();
   const navigate = useNavigate();

   const fetchUsers = async () => {
      setLoading(true);
      try {
         const response = await getUsers(searchObj);
         if (response.status === 200 && response?.data?.data?.users) {
            setUsers(response?.data?.data?.users);
            setTotal(response?.data?.data?.total || 0);
            setRoles(response?.data?.data?.roles || []);
         }
      } catch (error) {
         console.log(error);
      }
      setLoading(false);
   };

   async function updateUser(params: GridCellEditCommitParams, isDeleting?: boolean) {
      if (JSON.stringify(params.value) === editCellObj) return;
      try {
         console.log('patch');
         const response = await patchUser(params as PatchModel);
         if (!response.error) {
            snackbar.success(`User successfully ${isDeleting ? 'deleted' : 'updated'}.`);
         } else {
            snackbar.error(`Error while ${isDeleting ? 'deleteding' : 'updateding'} the user.`);
         }
      } catch (e) {
         console.log(e);
      }
   }

   useEffect(() => {
      fetchUsers();
   }, []);

   useEffect(() => {
      function updateGridHeight() {
         const topHeight = document.getElementById('top')?.clientHeight;
         setGridHeight(window.innerHeight - (topHeight ?? 0) ?? 200);
      }

      updateGridHeight();

      window.addEventListener('resize', updateGridHeight);

      return () => {
         window.removeEventListener('resize', updateGridHeight);
      };
   }, [users]);

   useEffect(() => {
      const timeOutId = setTimeout(() => {
         setSearchObj({ ...searchObj, page: 0 });
         fetchUsers();
      }, 500);
      return () => clearTimeout(timeOutId);
   }, [searchObj.search]);

   useEffect(() => {
      setSearchObj({ ...searchObj, page: 0 });
      fetchUsers();
   }, [searchObj.rowsPerPage, searchObj.orderBy, searchObj.asc]);

   useEffect(() => {
      fetchUsers();
   }, [searchObj.page]);

   const columns: GridColDef[] = [
      {
         field: 'firstname',
         headerName: 'First Name',
         editable: true,
         width: 100,
         filterable: false,
         flex: 1,
      },
      {
         field: 'lastname',
         headerName: 'Last Name',
         editable: true,
         filterable: false,
         flex: 1,
      },
      {
         field: 'isActive',
         headerName: 'Status',
         editable: true,
         filterable: false,
         renderEditCell: (params: GridRenderCellParams<any, any, any>) => <StatusEdit {...params} />,
         renderCell: (params: GridRenderCellParams<any, any, any>) => <RenderStatus {...params} />,
      },
      {
         field: 'email',
         headerName: 'Email',
         editable: true,
         filterable: false,
         flex: 1,
      },
      {
         field: 'userRoles',
         headerName: 'Role(s)',
         editable: true,
         filterable: false,
         sortable: false,
         flex: 1,
         renderEditCell: (params: GridRenderCellParams<any, any, any>) => <RolesEdit params={params} roles={roles} />,
         renderCell: (params: GridRenderCellParams<any, any, any>) => <RenderRoles {...params} />,
      },
      {
         field: 'createdAt',
         headerName: 'Creation',
         editable: false,
         filterable: false,
         flex: 1,
         renderCell: (params: GridRenderCellParams<any, any, any>) => <TimeAgo datetime={new Date(params.row.createdAt.toString())} />,
      },
      {
         field: 'edit',
         headerName: '',
         editable: false,
         sortable: false,
         filterable: false,
         disableColumnMenu: true,
         renderCell: (params: GridRenderCellParams<any, any, any>) => {
            return (
               <div className="flex gap-small">
                  {/* <IconButton
                     aria-label="edit"
                     component="label"
                     onClick={() =>
                        navigate(`/users/${params.row.id}`, {
                           replace: true,
                        })
                     }>
                     <EditIcon />
                  </IconButton> */}
                  <IconButton aria-label="delete" onClick={() => setSelectedObj(params.row.id)}>
                     <DeleteIcon />
                  </IconButton>
                  <ConfirmModal
                     selectedObj={selectedObj === params.row.id ? selectedObj : null}
                     setSelectedObj={setSelectedObj}
                     icon={<HighlightOffIcon color="error" fontSize="large" />}
                     title="Confirm Delete"
                     message="Are you sure you want to delete the selected user?"
                     buttonText="Delete"
                     onConfirm={() => {
                        updateUser(
                           {
                              id: selectedObj,
                              field: 'isDeleted',
                              value: true,
                           } as PatchModel,
                           true
                        );
                        setSearchObj({ ...searchObj, page: 0 });
                        setUsers(users.filter((user: User) => user.id !== selectedObj));
                        setTotal(total - 1);
                        setSelectedObj(null);
                     }}
                  />
               </div>
            );
         },
      },
   ];

   return (
      <div className="users flex column">
         <div id="top" className="p-4">
            <h1>Users</h1>
         </div>

         <DataGridPro
            sx={{ minHeight: gridHeight, height: gridHeight, maxHeight: gridHeight, backgroundColor: 'white' }}
            rows={users}
            columns={columns}
            rowCount={total}
            loading={loading}
            pageSize={searchObj.rowsPerPage}
            onPageSizeChange={(newPageSize: number) => {
               setSearchObj({
                  ...searchObj,
                  page: 0,
                  rowsPerPage: newPageSize,
               });
            }}
            disableSelectionOnClick={true}
            onPageChange={(page: number) => setSearchObj({ ...searchObj, page: page })}
            rowsPerPageOptions={[5, 10, 20, 100]}
            pagination
            paginationMode="server"
            sortingMode="server"
            sortModel={[
               {
                  field: searchObj.orderBy ?? '',
                  sort: searchObj.asc === true ? 'asc' : 'desc',
               },
            ]}
            onSortModelChange={(sortModel: GridSortModel) => {
               if (sortModel[0] === undefined) {
                  setSearchObj({ ...searchObj, asc: true });
               } else {
                  setSearchObj({
                     ...searchObj,
                     orderBy: sortModel[0]?.field ?? 'firstname',
                     asc: sortModel[0]?.sort == 'asc' ? true : false,
                  });
               }
            }}
            onCellEditStart={(params: GridCellParams) => setEditCellObj(JSON.stringify(params.value))}
            onCellEditCommit={(params: GridCellEditCommitParams) => updateUser(params)}
         />
      </div>
   );
}
