import { useEffect, useState } from 'react';
import { SimpleModal } from '../shared/modals/SimpleModal';
import { Button, CircularProgress, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackBar } from '../../contexts/SnackbarContext';
import { MessageForm } from '../../models/message-form.model';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { getReportSize } from '../../services/salesforce.service';
import { createBatch } from '../../services/batch.service';
export function CreateMessageModal(props: { open: boolean; onClose: () => void }) {
   const [form, setForm] = useState<MessageForm>({
      reportId: '',
      content: '',
   });

   const [numberOfPeople, setNumberOfPeople] = useState<{
      total?: number;
      loading: boolean;
   }>({
      total: undefined,
      loading: false,
   });
   const [loading, setLoading] = useState<boolean>(false);

   const navigate = useNavigate();
   const snackbar = useSnackBar();

   useEffect(() => {
      if (!props.open) {
         setForm({
            reportId: '',
            content: '',
         });
         setNumberOfPeople({
            total: undefined,
            loading: false,
         });
      }
   }, [props.open]);

   const handleCreate = async (e: any) => {
      e.preventDefault();
      setLoading(true);
      try {
         const newMessage: any = await createBatch({
            reportId: form.reportId,
            content: form.content,
            type: 'message',
            isDeleted: false,
         });
         if (newMessage?.data?.data?.id) {
            snackbar.success('Message successfully created.');
            setTimeout(() => {
               navigate(`/messages/${newMessage?.data?.data?.id}`);
            }, 2000);
         }
         setTimeout(() => {
            setLoading(false);
         }, 2500);
      } catch (e) {
         console.log(e);
         snackbar.error('Error while creating the message.');
         setLoading(false);
      }
   };

   const fetchNumberOfPeople = async () => {
      setNumberOfPeople({
         ...numberOfPeople,
         loading: true,
      });
      try {
         const reportSize: any = await getReportSize(form.reportId);
         console.log(reportSize);
         // setTimeout(() => {
         setNumberOfPeople({
            total: reportSize?.data?.data ?? 0,
            loading: false,
         });
         // }, 2000);
      } catch (error) {
         console.log(error);
         setNumberOfPeople({
            ...numberOfPeople,
            loading: false,
         });
      }
   };

   return (
      <SimpleModal style={{ minWidth: '50vw' }} open={props.open} setOpen={props.onClose}>
         <div className="flex column">
            <div className="flex start">
               <Typography sx={{ mb: 1 }} variant="h6" component="div">
                  New Message
               </Typography>
            </div>
            <div>
               <form onSubmit={handleCreate} className="">
                  <div className="flex column gap">
                     <div className="flex gap items-center">
                        <TextField
                           autoFocus
                           className="w-70"
                           label="Report ID"
                           type={'text'}
                           variant="outlined"
                           value={form.reportId}
                           onChange={(event: any) => {
                              setNumberOfPeople({
                                 loading: false,
                                 total: undefined,
                              });
                              setForm({ ...form, reportId: event.target.value });
                           }}
                           required={true}
                        />

                        {numberOfPeople.loading ? (
                           <CircularProgress size={25} />
                        ) : numberOfPeople.total !== undefined ? (
                           <p>{numberOfPeople.total.toLocaleString()} users will receive this message.</p>
                        ) : (
                           <Tooltip title="Search the number of users that will receive this message." placement="right">
                              <IconButton onClick={fetchNumberOfPeople}>
                                 <PersonSearchIcon />
                              </IconButton>
                           </Tooltip>
                        )}
                     </div>

                     <TextField
                        className="w-100"
                        label="Content"
                        type={'text'}
                        variant="outlined"
                        multiline
                        minRows={4}
                        maxRows={25}
                        value={form.content}
                        onChange={(event: any) => setForm({ ...form, content: event.target.value })}
                        required={true}
                     />
                  </div>

                  <div className="flex end">
                     {
                        loading ? (
                           <div style={{height: '60.5px'}} className='flex items-end mr-3'>
                              <CircularProgress size={25} />
                           </div>
                        ) : (
                           <>
                              <Button className="mt-4 mr-2" variant="text" onClick={props.onClose}>
                                 CANCEL
                              </Button>
                              <Button className="mt-4" color="primary" variant="contained" type="submit" disabled={form.reportId === '' || form.content === ''}>
                                 CREATE
                              </Button>
                           </>
                        )
                     }
                  </div>
               </form>
            </div>
         </div>
      </SimpleModal>
   );
}
