const API_URL = process.env.REACT_APP_API as String;
const apiEndpoint = 'salesforce';

export const getReportSize = async (id: string): Promise<any> => {
   const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' as any,
   };
   return fetch(`${API_URL}${apiEndpoint}/report/${id}`, requestOptions).then(async (response) => {
      return { status: response.status, data: await response.json() };
   });
};
