import { useEffect, useState } from "react";
import { Message } from "../models/message.model";
import { MessageSearchModel } from "../models/search.model";
import { useParams } from "react-router-dom";
import { getMessages, getReport } from "../services/message.service";
import { GridColDef, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid";
import TimeAgo from "timeago-react";
import { CircularProgress } from "@mui/material";
import { ConfirmModal } from "./shared/modals/ConfirmModal";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getBatch, refreshStatus } from "../services/batch.service";
import { Batch } from "../models/batch.model";

export function MessageBatch() {
    const { batchId } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingRefresh, setLoadingRefresh] = useState<boolean>(false);
    const [loadingReport, setLoadingReport] = useState<boolean>(false);
    const [messages, setMessages] = useState<Message[]>([]);
    const [batch, setBatch] = useState<Batch>({} as Batch);
    const [total, setTotal] = useState(0);
    const [status, setStatus] = useState<{status: string; count: number}[]>([]);
    const [searchObj, setSearchObj] = useState<MessageSearchModel>({
       batchId: batchId ?? '',
       page: 0,
       rowsPerPage: 100,
       search: '',
       orderBy: 'createdAt',
       asc: false,
    });
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const [gridHeight, setGridHeight] = useState<number>(200);
 
    const fetchBatchMessages = async () => {
       setLoading(true);
       try {
          const response = await getMessages(searchObj);
          if (response.status === 200 && response?.data?.data?.messages) {
            setMessages(response?.data?.data?.messages);
            setTotal(response?.data?.data?.total || 0);
            setStatus(response?.data?.data?.status || []);
          }
       } catch (error) {
          console.log(error);
       }
       setLoading(false);
    };

    const fetchBatch = async () => {
      if (!batchId) return;
      try {
         const response = await getBatch(batchId);
         if (response.status === 200 && response?.data?.data) {
            setBatch(response?.data?.data);
         }
      } catch (error) {
         console.log(error);
      }
   };

   useEffect(() => {
      if (batch.status === 'CREATED') {
         setTimeout(() => {
            fetchBatch();
            fetchBatchMessages();
         }, 20000);
      }
   }, [batch]);

    const refresh = async () => {
      if (!batchId) {
         console.log('no batch id');
         return;
      }
      setLoadingRefresh(true);
      try {
         await refreshStatus(batchId);
         setLoadingRefresh(false);
         await fetchBatchMessages();
         await fetchBatch();
      } catch (error) {
         console.log(error);
      }
      setLoadingRefresh(false);
    };
 
   const generateReport = async () => {
      if (!batch.createdAt) return;
      try {
         setLoadingReport(true);

         const endDate = new Date(messages.sort((a: Message, b: Message) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0].createdAt);
         endDate.setMinutes(endDate.getMinutes() + 1); 
         const report = await getReport(batch.createdAt, endDate);

         if (report.status !== 200) {
            console.log('error getting messages');
            setLoadingReport(false);
            return;
         }

         const csvData = report.data.data.filter((mes: any) => mes.body === batch.content).map((message: any) => ({
            Sid: message.sid,
            AccountSid: message.accountSid,
            ApiVersion: message.apiVersion,
            Body: message.body,
            DateCreated: message.dateCreated,
            DateSent: message.dateSent,
            DateUpdated: message.dateUpdated,
            Direction: message.direction,
            ErrorCode: message.errorCode || '',
            ErrorMessage: message.errorMessage || '',
            From: message.from,
            MessagingServiceSid: message.messagingServiceSid,
            NumMedia: message.numMedia,
            NumSegments: message.numSegments,
            Price: message.price,
            PriceUnit: message.priceUnit,
            Status: message.status,
            To: message.to,
          }));
      
          const headers = Object.keys(csvData[0]);

         const csvContent = [
            headers.join(','),
            ...csvData.map((row: any) => headers.map((header) => row[header]).join(',')),
         ].join('\n');

         const blob = new Blob([csvContent], { type: 'text/csv' });
         const url = URL.createObjectURL(blob);

         const link = document.createElement('a');
         link.href = url;
         link.download = `report_twilio_${batch.id}.csv`;
         document.body.appendChild(link);
         link.click();

         document.body.removeChild(link);
         URL.revokeObjectURL(url);
      } catch (error) {
         console.log(error);
      }
      setLoadingReport(false);
   };
 
    useEffect(() => {
       function updateGridHeight() {
          const topHeight = document.getElementById('top')?.clientHeight;
          setGridHeight(window.innerHeight - (topHeight ?? 0) ?? 200);
       }
 
       updateGridHeight();
 
       window.addEventListener('resize', updateGridHeight);
 
       return () => {
          window.removeEventListener('resize', updateGridHeight);
       };
    }, [messages]);

    useEffect(() => {
      fetchBatch();
    }, [batchId]);
 
    useEffect(() => {
       const timeOutId = setTimeout(() => {
          setSearchObj({ ...searchObj });
          fetchBatchMessages();
       }, 500);
       return () => clearTimeout(timeOutId);
    }, [searchObj.search, searchObj.rowsPerPage, searchObj.orderBy, searchObj.asc, searchObj.page]);
 
   //  useEffect(() => {
   //     setSearchObj({ ...searchObj, page: 0 });
   //     fetchBatchMessages();
   //  }, [searchObj.rowsPerPage, searchObj.orderBy, searchObj.asc]);
 
   //  useEffect(() => {
   //      fetchBatchMessages();
   //  }, [searchObj.page]);
 
    const columns: GridColDef[] = [
       {
          field: 'content',
          headerName: 'Content',
          editable: false,
          filterable: false,
          flex: 1,
       },
       {
        field: 'status',
        headerName: 'Status',
        editable: false,
        filterable: false,
        flex: 1,
        },
       {
          field: 'source',
          headerName: 'Source',
          editable: false,
          filterable: false,
          flex: 1,
       },
       {
            field: 'recipient',
            headerName: 'Recipient',
            editable: false,
            filterable: false,
            flex: 1,
        },
       {
          field: 'createdAt',
          headerName: 'Creation',
          editable: false,
          filterable: false,
          flex: 1,
          renderCell: (params: GridRenderCellParams<any, any, any>) => <TimeAgo datetime={new Date(params.row.createdAt.toString())} />,
       },
       {
         field: 'updatedAt',
         headerName: 'Last Refresh',
         editable: false,
         filterable: false,
         flex: 1,
         renderCell: (params: GridRenderCellParams<any, any, any>) => <TimeAgo datetime={new Date(params.row.updatedAt.toString())} />,
      },
       {
          field: 'edit',
          headerName: '',
          editable: false,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams<any, any, any>) => {
             return (
                <div className="flex gap-small">
                   {/* <IconButton
                      aria-label="edit"
                      component="label"
                      onClick={() =>
                         navigate(`/users/${params.row.id}`, {
                            replace: true,
                         })
                      }>
                      <EditIcon />
                   </IconButton> */}
                   {/* <IconButton aria-label="delete" onClick={() => setSelectedObj(params.row.id)}>
                      <DeleteIcon />
                   </IconButton> */}
                   <ConfirmModal
                      selectedObj={selectedObj === params.row.id ? selectedObj : null}
                      setSelectedObj={setSelectedObj}
                      icon={<HighlightOffIcon color="error" fontSize="large" />}
                      title="Confirm Delete"
                      message="Are you sure you want to delete the selected user?"
                      buttonText="Delete"
                      onConfirm={() => {
                        //  updateBatch(
                        //     {
                        //        id: selectedObj,
                        //        field: 'isDeleted',
                        //        value: true,
                        //     } as PatchModel,
                        //     true
                        //  );
                         setSearchObj({ ...searchObj, page: 0 });
                         setMessages(messages.filter((message: Message) => message.id !== selectedObj));
                         setTotal(total - 1);
                         setSelectedObj(null);
                      }}
                   />
                </div>
             );
          },
       },
    ];
 
    return (
       <div className="users flex column">
         <div className="flex between items-center">
            <div id="top" className="p-4">
               <h1>Batch</h1>
               <h5 className="mt-0 mb-0">{batchId}</h5>
               <h5 className="mt-0 mb-0">{messages.length} messages</h5>
               <h5 className="mt-0 mb-0">{messages.length > 0 ? new Date(messages[0].createdAt).toUTCString() : ''}</h5>
               <h5 className="mt-0 mb-0">Status: {batch.status}</h5>
            </div>
            <div className="flex items-center">
                  {
                     status.map((s, index) => {
                        return (
                           <div className="flex gap-small p-4" key={'status-' + index}>
                              <h4 className="mt-0 mb-0 self-end">{s.status}</h4>
                              <h3 className="mt-0 mb-0">{s.count}</h3>
                           </div>
                        );
                     })
                  }
            </div>
            <div className="p-4 flex column gap">
               {
                  batch && batch.status === 'CREATED' ? (
                     <div className="flex items-center gap">
                        PROCESSING...
                        <CircularProgress size={25} />
                     </div>
                  ) : (
                     loadingRefresh ? 
                     <button disabled>REFRESHING...</button> :
                     <button className="pointer" onClick={() => refresh()}>REFRESH STATUS</button> 
                  )
               } 
               {
                  loadingReport ? 
                  <button disabled>GENERATING REPORT...</button> :
                  <button className="pointer" onClick={() => generateReport()}>TWILIO REPORT</button> 
               } 
            </div>
         </div>
 
          <DataGridPro
             sx={{ minHeight: gridHeight, height: gridHeight, maxHeight: gridHeight, backgroundColor: 'white' }}
             rows={messages}
             columns={columns}
             rowCount={total}
             loading={loading}
             pageSize={searchObj.rowsPerPage}
             onPageSizeChange={(newPageSize: number) => {
                setSearchObj({
                   ...searchObj,
                   page: 0,
                   rowsPerPage: newPageSize,
                });
             }}
             disableSelectionOnClick={true}
             onPageChange={(page: number) => setSearchObj({ ...searchObj, page: page })}
             rowsPerPageOptions={[5, 10, 20, 100]}
             pagination
             paginationMode="server"
             sortingMode="server"
             sortModel={[
                {
                   field: searchObj.orderBy ?? '',
                   sort: searchObj.asc === true ? 'asc' : 'desc',
                },
             ]}
             onSortModelChange={(sortModel: GridSortModel) => {
                if (sortModel[0] === undefined) {
                   setSearchObj({ ...searchObj, asc: true });
                } else {
                   setSearchObj({
                      ...searchObj,
                      orderBy: sortModel[0]?.field ?? 'createdAt',
                      asc: sortModel[0]?.sort == 'asc' ? true : false,
                   });
                }
             }}
          />
       </div>
    );
 }
 