export function Emails() {
   return (
      <div className="p-4">
         <div>
            <h1>Emails</h1>
         </div>
         <div>content</div>
      </div>
   );
}
