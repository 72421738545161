import { useContext, useEffect } from 'react';
import { refreshToken, signout } from '../services/user.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

export function AuthProvider() {
   const { isAuthenticated, setAuthenticated } = useContext(AuthContext);
   const navigate = useNavigate();
   const location = useLocation();

   useEffect(() => {
      if (isAuthenticated) {
         refreshToken().then((response) => {
            if (response.status === 403) {
               signout().then(() => {
                  localStorage.removeItem('token');
                  navigate('/login');
               });
            }
         });

         const token = localStorage.getItem('token');
         if (!token) return;

         const intervalId = setInterval(async () => {
            const response = await refreshToken();
            if (!response.error) {
               localStorage.setItem('token', response?.token);
            } else {
            }
         }, 300000);

         return () => clearInterval(intervalId);
      } else {
         if (localStorage.getItem('token')) {
            signout().then(() => {
               localStorage.removeItem('token');
               navigate('/login');
            });
         } else {
            navigate('/login');
         }
      }
   }, [isAuthenticated]);

   useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const isLogout = queryParams.get('logout') === 'true';
      if (isLogout && isAuthenticated) setAuthenticated(false);
   }, [location.search]);

   return null;
}
