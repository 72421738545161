import { useEffect, useState } from 'react';
import { Batch } from '../models/batch.model';
import { BatchSearchModel, SearchModel } from '../models/search.model';
import { useSnackBar } from '../contexts/SnackbarContext';
import { useNavigate } from 'react-router-dom';
import { getBatches, patchBatch } from '../services/batch.service';
import { GridCellEditCommitParams, GridCellParams, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import { PatchModel } from '../models/path.model';
import TimeAgo from 'timeago-react';
import { Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmModal } from './shared/modals/ConfirmModal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DataGridPro } from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import MessageIcon from '@mui/icons-material/Message';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CreateMessageModal } from './modals/CreateMessageModal';

export function Messages() {
   const [loading, setLoading] = useState<boolean>(false);
   const [batches, setBatches] = useState<Batch[]>([]);
   const [total, setTotal] = useState(0);
   const [searchObj, setSearchObj] = useState<BatchSearchModel>({
      type: 'message',
      page: 0,
      rowsPerPage: 100,
      search: '',
      orderBy: 'createdAt',
      asc: false,
   });
   const [editCellObj, setEditCellObj] = useState<string>();
   const [selectedObj, setSelectedObj] = useState<any>(null);
   const [gridHeight, setGridHeight] = useState<number>(200);
   const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);

   const snackbar = useSnackBar();
   const navigate = useNavigate();

   const fetchBatches = async () => {
      setLoading(true);
      try {
         const response = await getBatches(searchObj);
         if (response.status === 200 && response?.data?.data?.batches) {
            setBatches(response?.data?.data?.batches);
            setTotal(response?.data?.data?.total || 0);
         }
      } catch (error) {
         console.log(error);
      }
      setLoading(false);
   };

   async function updateBatch(params: GridCellEditCommitParams, isDeleting?: boolean) {
      if (JSON.stringify(params.value) === editCellObj) return;
      try {
         console.log('patch');
         const response = await patchBatch(params as PatchModel);
         if (!response.error) {
            snackbar.success(`Batch successfully ${isDeleting ? 'deleted' : 'updated'}.`);
         } else {
            snackbar.error(`Error while ${isDeleting ? 'deleteding' : 'updateding'} the batch.`);
         }
      } catch (e) {
         console.log(e);
      }
   }

   // useEffect(() => {
   //    fetchBatches();
   // }, []);

   useEffect(() => {
      function updateGridHeight() {
         const topHeight = document.getElementById('top')?.clientHeight;
         setGridHeight(window.innerHeight - (topHeight ?? 0) ?? 200);
      }

      updateGridHeight();

      window.addEventListener('resize', updateGridHeight);

      return () => {
         window.removeEventListener('resize', updateGridHeight);
      };
   }, [batches]);

   useEffect(() => {
      const timeOutId = setTimeout(() => {
         setSearchObj({ ...searchObj, page: 0 });
         fetchBatches();
      }, 500);
      return () => clearTimeout(timeOutId);
   }, [searchObj.search, searchObj.rowsPerPage, searchObj.orderBy, searchObj.asc]);

   // useEffect(() => {
   //    setSearchObj({ ...searchObj, page: 0 });
   //    fetchBatches();
   // }, [searchObj.rowsPerPage, searchObj.orderBy, searchObj.asc]);

   // useEffect(() => {
   //    fetchBatches();
   // }, [searchObj.page]);

   const columns: GridColDef[] = [
      {
         field: 'content',
         headerName: 'Content',
         editable: false,
         filterable: false,
         flex: 1,
      },
      {
         field: 'size',
         headerName: 'Size',
         editable: false,
         filterable: false,
         flex: 1,
      },
      {
         field: 'reference',
         headerName: 'Report',
         editable: false,
         filterable: false,
         flex: 1,
      },
      {
         field: 'createdAt',
         headerName: 'Creation',
         editable: false,
         filterable: false,
         flex: 1,
         renderCell: (params: GridRenderCellParams<any, any, any>) => <TimeAgo datetime={new Date(params.row.createdAt.toString())} />,
      },
      {
         field: 'edit',
         headerName: '',
         editable: false,
         sortable: false,
         filterable: false,
         disableColumnMenu: true,
         renderCell: (params: GridRenderCellParams<any, any, any>) => {
            return (
               <div className="flex gap-small">
                  <IconButton aria-label="edit" component="label" onClick={() => navigate(`/messages/${params.id}`)}>
                     <ArrowForwardIcon />
                  </IconButton>
                  <IconButton aria-label="delete" onClick={() => setSelectedObj(params.row.id)}>
                     <DeleteIcon />
                  </IconButton>
                  <ConfirmModal
                     selectedObj={selectedObj === params.row.id ? selectedObj : null}
                     setSelectedObj={setSelectedObj}
                     icon={<HighlightOffIcon color="error" fontSize="large" />}
                     title="Confirm Delete"
                     message={`Are you sure you want to delete the selected batch?`}
                     buttonText="Delete"
                     onConfirm={() => {
                        updateBatch(
                           {
                              id: selectedObj,
                              field: 'isDeleted',
                              value: true,
                           } as PatchModel,
                           true
                        );
                        setSearchObj({ ...searchObj, page: 0 });
                        setBatches(batches.filter((batch: Batch) => batch.id !== selectedObj));
                        setTotal(total - 1);
                        setSelectedObj(null);
                     }}
                  />
               </div>
            );
         },
      },
   ];

   return (
      <div className="users flex column">
         <div id="top" className="p-4 flex items-center between">
            <h1>Messages</h1>
            <Button color="primary" size="medium" variant="outlined" startIcon={<MessageIcon />} onClick={() => setCreateModalOpen(true)}>
               CREATE
            </Button>
            <CreateMessageModal open={createModalOpen} onClose={() => setCreateModalOpen(false)} />
         </div>

         <DataGridPro
            sx={{ minHeight: gridHeight, height: gridHeight, maxHeight: gridHeight, backgroundColor: `white` }}
            rows={batches}
            columns={columns}
            rowCount={total}
            loading={loading}
            pageSize={searchObj.rowsPerPage}
            onPageSizeChange={(newPageSize: number) => {
               setSearchObj({
                  ...searchObj,
                  page: 0,
                  rowsPerPage: newPageSize,
               });
            }}
            getRowClassName={() => 'pointer'}
            disableSelectionOnClick={true}
            onPageChange={(page: number) => setSearchObj({ ...searchObj, page: page })}
            rowsPerPageOptions={[5, 10, 20, 100]}
            pagination
            paginationMode="server"
            sortingMode="server"
            sortModel={[
               {
                  field: searchObj.orderBy ?? '',
                  sort: searchObj.asc === true ? 'asc' : 'desc',
               },
            ]}
            onSortModelChange={(sortModel: GridSortModel) => {
               if (sortModel[0] === undefined) {
                  setSearchObj({ ...searchObj, asc: true });
               } else {
                  setSearchObj({
                     ...searchObj,
                     orderBy: sortModel[0]?.field ?? 'createdAt',
                     asc: sortModel[0]?.sort == 'asc' ? true : false,
                  });
               }
            }}
            // onCellClick={(params: GridCellParams) => {
            //    navigate(`/messages/${params.id}`);
            // }}
         />
      </div>
   );
}
